<template>
	<svg height="200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21 17.2L6.8 3H19C20.1 3 21 3.9 21 5V17.2M20.7 22L19.7 21H5C3.9 21 3 20.1 3 19V4.3L2 3.3L3.3 2L22 20.7L20.7 22M16.8 18L12.9 14.1L11 16.5L8.5 13.5L5 18H16.8Z"
			fill="#1697f6"/>
	</svg>
</template>

<script>
export default {
	name: 'MissingImage'
}
</script>

<style scoped>

</style>
